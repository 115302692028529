import "react-datepicker/dist/react-datepicker.css";
import "@styles/globals.css";
import "@styles/datepicker-custom.css";
import "@styles/ckeditor5-editor.css";
import { appWithTranslation } from "next-i18next";
import { datadogRum } from '@datadog/browser-rum';
datadogRum.init({
  applicationId: '31d98611-acea-48f2-80d9-6d64f32efd55',
  clientToken: 'pubf9f74eff2ec2c568b3ad8e139daa26ea',
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'datadoghq.com',
  service: 'kbpay-www',
  env: process.env.NEXT_PUBLIC_DEV_ENV_INIT,
  version: process.env.NEXT_PUBLIC_PACKAGE_VERSION,
  sessionSampleRate: 10,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  allowedTracingUrls: [/^https:\/\/(dev-|stage-)?api\.tripbtoz\.com/, /^https:\/\/(dev-|stage-)?push-api\.tripbtoz\.com/, /^https:\/\/(dev-|stage-)?payments\.tripbtoz\.com/, /^https:\/\/.*\.triptoz\.com\/api/, /^https:\/\/.*\.kbtoz\.com\/api/, /https:\/\/.*\.triptoz\.com/, /https:\/\/.*\.kbtoz\.com/]
});
import TTBBMetaHead from "../components/meta/TTBBMetaHead";
import HeaderTTBBScript from "@components/layout/HeaderTTBBScript";
function App({
  Component,
  pageProps
}) {
  return <>
      <TTBBMetaHead pageProps={pageProps} />
      {process.env.NEXT_PUBLIC_DEV_ENV !== "development" ? <HeaderTTBBScript /> : <></>}
      <Component {...pageProps} />
  </>;
}
export default appWithTranslation(App);